import { apenasNumeros } from '@/@core/utils/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,

  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    fetchDados({ commit }, queryParams) {
      console.log('chegou store')
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/leads', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    fetchEmpresas() {
      return new Promise((resolve, reject) => {
        axios
          .get('/empresas')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`leads/${item.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, userData) {
      const formData = new FormData()

      if (userData.razao_social) {
        formData.append('razao_social', userData.razao_social)
      }
      if (userData.email) {
        formData.append('email', userData.email)
      }
      if (userData.telefone) {
        formData.append('telefone', userData.telefone)
      }
      if (userData.contato_responsavel) {
        formData.append('contato_responsavel', userData.contato_responsavel)
      }

      if (userData.cpf) {
        formData.append('cpf', userData.cpf)
      }
      if (userData.cnpj) {
        formData.append('cnpj', userData.cnpj)
      }

      if (userData.endereco) {
        Object.keys(userData.endereco)
          .forEach(chave => {
            if (userData.endereco[chave]) {
              formData.append(`endereco[${chave}]`, userData.endereco[chave])
            }

          })
      }

      if (userData.empresas) {
        Object.keys(userData.empresas)
          .forEach(chave => {
            formData.append(`empresas[${chave}]`, userData.empresas[chave])
          })
      }

      if (userData.logo) {
        formData.append('imagem', userData.logo)
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/leads', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, userData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('razao_social', userData.razao_social)
        formData.append('email', userData.email)
        formData.append('telefone', apenasNumeros(userData.telefone))

        formData.append('uuid', userData.uuid)
        formData.append('contato_responsavel', userData.contato_responsavel)

        if (userData.cpf) {
          formData.append('cpf', userData.cpf)
        }
        if (userData.cnpj) {
          formData.append('cnpj', userData.cnpj)
        }

        Object.keys(userData.empresas)
          .forEach(chave => {
            formData.append(`empresas[${chave}]`, userData.empresas[chave])
          })

        Object.keys(userData.endereco)
          .forEach(chave => {
            formData.append(`endereco[${chave}]`, userData.endereco[chave])
          })

        if (userData.logo) {
          formData.append('imagem', userData.logo)
        }

        formData.append('_method', 'PUT')

        axios
          .post(`clientes/${userData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteCliente(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/leads/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
